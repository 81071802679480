.articleWrapper {
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 0.9px #d0d0d0;
  background-color: #fffdfd;
  margin: 0 5px;
}

.backgroundRectangle {
  background-color: rgb(255, 165, 87, 0.2);
}

.backgroundTitle {
  color: #fa6400;
}

.articleImage {
  border-radius: 8px 8px 0 0;
  margin: auto;
  max-width: 100%;
}

.articleTitle {
  padding-left: 27px;
  padding-right: 28px;
  box-sizing: border-box;
  width: 100%;
  font-family: Lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-top: 12px;
  letter-spacing: normal;
  color: #303030;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: calc(72px + 2rem);
  margin-bottom: 0;
}

.articleReadButton {
  background-color: rgba(182, 32, 224, 0.1);
  border: none;
  display: block;
  padding: 1rem 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.articleReadButtonWrapper {
  background-color: rgba(182, 32, 224, 0.1);
}

.articleReadButton span {
  width: 52px;
  height: 23px;
  margin: 0 5px 0 0;
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff3278;
}

.forwardArrowIcon g {
  fill: #ff3278;
}

.read {
  color: #ff3278;
  font-family: Lato;
  font-size: 19.3px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 23px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 5px 0 0;
  width: 52px;
}

.link {
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .articleTitle {
    padding-left: 11px;
    padding-right: 8px;
  }
  .articleReadButton {
    height: 25px;
  }
  .forwardArrowIcon {
    height: 10px;
    width: 10px;
  }
  .read {
    font-size: 14px;
  }
}
