.footer {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 3rem;
}

.playstore {
  margin-top: 0.5em;
  width: 120px;
}

.slideWrapper {
  margin-top: 0.5rem;
  width: 80%;
}

.wrapper {
  align-items: center;
  background-color: rgba(104, 159, 56, 0.1);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 1rem 0;
  padding: 1rem 0;
}

@media only screen and (min-width: 600px) {
  .footer {
    flex-flow: row nowrap;
  }

  .playstore {
    margin-left: 3rem;
  }
}
