.image {
  border-radius: 12px 12px 0 0;
  width: 100%;
}

.imageText {
  align-items: flex-end;
  bottom: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 1rem 0;
  max-width: 90%;
  position: absolute;
}

.imageText h2 {
  color: #fff;
  padding: 0 20px;
  margin: 0 1em 0 0;
  width: 180px;
}

.seeMore {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.37), #f5f5f5 76%);
  border-radius: 0 0 12px 12px;
  bottom: 0;
  color: #ff3278;
  left: 0;
  padding: 4rem 2rem 1rem;
  position: absolute;
  right: 0;
}

.stage {
  background-color: #f9f9f9;
  border-radius: 12px;
  color: #333;
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  margin: 0 1rem 1rem;
  padding-bottom: 3rem;
  position: relative;
  text-decoration: none;
}

.stageImage {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .stage {
    font-size: unset;
  }
}
