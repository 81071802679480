.container {
  margin-top: 12px;
}

.dots {
  bottom: -25px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.showcasePrevButtonWrapper {
  position: absolute;
  top: 50%;
  left: -3%;
  z-index: 1;
  transform: translateY(-50%);
}

.showcaseNextButtonWrapper {
  position: absolute;
  top: 50%;
  right: -3%;
  z-index: 1;
  transform: translateY(-50%);
}

.overrideContent::before {
  content: '' !important;
}

.forwardArrowIcon g {
  fill: #ff3278 !important;
}
