.header {
  color: #ff3278;
  margin-left: 7px;
}

.header h4 {
  margin: 0;
}

.header h3 {
  margin: 0;
  margin-top: 5px;
}

.topics {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.topicsLine {
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
}

.topicImage {
  width: 33%;
}

.wrapper {
  margin: 0 10px;
}

@media only screen and (min-width: 1200px) {
  .topicImage {
    min-width: 15%;
    max-width: 100%;
    width: 15%;
  }

  .topics {
    flex-flow: row nowrap;
    margin-left: -0.6%;
    margin-bottom: -0.6%;
    justify-content: flex-start;
  }

  .topics > div {
    margin-left: 0.6%;
    margin-bottom: 0.6%;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .topicsLine {
    width: 50%;
  }
}
