.icon {
  width: 125px;
}

.link {
  color: #333;
  text-decoration: none;
}

.text {
  margin-bottom: 1rem;
  width: 80%;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 1rem 0 0;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .text {
    max-width: 50%;
  }
}
