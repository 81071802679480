.showcaseArticleWrapper {
  display: flex;
  align-items: center;
  margin-top: 1%;
  overflow-x: auto;
}

.showcaseBackwardButton,
.showcaseForwardButton {
  cursor: pointer;
  height: 45px;
  width: 45px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  line-height: 45px;
}
.showcaseBackwardButton {
  margin-right: 28px;
}

.showcaseForwardButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.showcaseWrapperContent {
  width: 100%;
  min-height: 165px;
  position: relative;
}

.showcaseWrapperConent {
  width: 90%;
}
.space {
  flex: 1;
}

.displayNone {
  display: none;
}

.visiblityHidden {
  visibility: hidden;
}

.showcaseWrapper {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: -200px;
  padding: 0 10px;
}

.wrapper {
  margin: 1rem 0;
}

@media only screen and (max-width: 700px) {
  .showcaseWrapper {
    width: 85%;
    margin-top: -175px;
  }
}
