.arrow {
  font-size: 24px;
}

.bar {
  background-color: #f5f5f5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem 0 0.5rem 4px;
}

.option {
  align-items: center;
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  flex-flow: column;
  font-size: smaller;
  justify-content: flex-start;
  padding: 8px 0;
  text-decoration: none;
  width: 25%;
}

.optionImage {
  margin-bottom: 5px;
  width: 36px;
}

.optionText {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
}

.shop {
  background-image: linear-gradient(to left, #6e7ae3 100%, #976ee3 0%);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  color: white;
}

.shopText {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 0.5em;
}

@media only screen and (min-width: 800px) {
  .bar {
    background-color: #fff;
  }

  .option {
    border-radius: 2%;
    flex-flow: row;
    margin: 0 0.5em;
    padding: 1em;
  }

  .option:first-of-type {
    margin-left: 0;
  }

  .option:last-of-type {
    margin-right: 0;
  }

  .optionImage {
    margin-bottom: 0;
  }

  .optionText {
    margin-left: 1em;
    text-align: left;
  }

  .shop {
    border-radius: 0 8px 8px 0;
  }

  .shop .optionImage {
    height: auto;
  }
}
