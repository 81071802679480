.head {
  color: #ff3278;
  margin: 0;
}

.header {
  margin: 0 1rem 1rem;
}

@media only screen and (min-width: 1200px) {
  .stages {
    display: flex;
  }
}
