.topic {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem 0.2rem;
  text-decoration: none;
}

.topicImage {
  border-radius: 5%;
  width: 100%;
}

.topicText {
  color: #303030;
  margin-top: 10px;
  text-align: center;
  width: 75%;
  word-wrap: break-word;
}

@media only screen and (min-width: 600px) {
  .topic {
    flex: 1;
  }
}
