.backgroundActionButtonWrapper {
  padding: 9px 16px 8px 19px;
  border-radius: 3.4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #ff3278;
  cursor: pointer;
}

.backgroundActionButtonTitle {
  align-items: center;
  background: #ff3278;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Lato;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 7px 0 0;
  text-decoration: none;
}

.backgroundWrapper {
  display: flex;
  align-items: center;
}

.backgroundTitleWrapper {
  display: flex;
  flex-direction: column;
}

.backgroundSubTitle {
  font-family: Lato;
  font-size: 18.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7a7a7a;
}

.backgroundTitle {
  font-family: Lato;
  font-size: 28px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b620e0;
}

.space {
  flex: 1;
}

@media only screen and (max-width: 700px) {
  .backgroundTitle {
    font-size: 14px;
  }
  .backgroundActionButtonWrapper {
    padding: 4px 8px;
  }
  .backgroundActionButtonTitle {
    font-size: 12px;
    margin: 0;
  }
  .backgroundActionButton {
    width: 10px;
    height: 10px;
  }
  .backgroundSubTitle {
    font-size: 11px;
  }
}
