.articleOuterWrapper {
  height: 350px;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 0.9px #d0d0d0;
  background-color: #fffdfd;
  position: relative;
  margin: 0 5px;
}

.articleTitle {
  width: 100%;
  box-sizing: border-box;
  margin: 0 2px 4px 1px;
  font-family: Lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.articleAnswerCount {
  height: 22px;
  margin: 4px;
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.articleAnswerAuthor {
  margin: 0 0 2px 0px;
  font-family: Lato;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.articleAnswerDescription {
  margin: 11px 16px 0 1px;
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--black);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.articleAnswerReadMore {
  color: #ff3278;
  text-decoration: none;
}

.articleAnswerAuthorName {
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.articleAnswerProfilePic {
  border-radius: 50%;
}

.articleDivisionLine {
  border: 1px solid #d0d0d0;
}

.articleWrapper {
  padding: 30px 28px 28px 27px;
  box-sizing: border-box;
}

@media only screen and (max-width: 700px) {
  .articleWrapper {
    padding: 11px 8px 14px 11px;
  }
  .articleAnswerCount {
    height: 15px;
    margin: 2px 0 2px 2px;
  }
  .articleAnswerDescription {
    margin: 0;
  }
  .articleAnswerProfilePic {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 700px) {
  .articleWrapper {
    height: 245px;
  }
}
