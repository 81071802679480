.backgroundRectangle {
  background-color: rgba(182, 32, 224, 0.1);
  border-radius: 2px;
  height: 230px;
  margin: 0 0 48px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .backgroundRectangle {
    height: 150px;
    margin-left: 0a;
  }
}
