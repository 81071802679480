.banner {
  width: 100%;
}

.pink {
  background-color: #ffe6ee;
  margin-top: 12px;
  min-width: 20px;
  width: calc(100% - 420px);
}

.placeholder {
  width: 100%;
  height: 100vh;
}

.slideWrapper {
  margin: 0 1em;
  max-width: 500px;
  width: calc(100% - 80px);
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 3rem;
}
