.card {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  font-size: 12px;
  justify-content: center;
  margin: 0 0.5rem;
  min-height: 120px;
  padding: 1rem 5px;
  text-align: center;
}

.emptyStar {
  font-size: 18px;
  color: #fff;
}

.fullStar {
  font-size: 18px;
  color: #ffa557;
}

.profilePic {
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  line-clamp: 3;
  margin-top: 0.5rem;
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
  .card {
    font-size: unset;
    padding: 2rem;
  }
}
